/**
 * !! DEPRECATED !!
 * Will be removed when all tenants will be migrated to auth.pulsegw.com
 */

import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import useHotjarScript from '../utils/hooks/useHotjarScript'

// Encoding UTF8 to base64
// function b64EncodeUnicode(str) {
//   return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
//     return String.fromCharCode(parseInt(p1, 16))
//   }))
// }

// Decoding base64 to UTF8
function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

function getQueryStringParam(name) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

function getStateData() {
  /* State Schema: {
      tid: string // e.g. "edcab0c2-3c9f-4cdb-882c-79c79a400b22"
      tn: string // e.g. "Sametab Inc"
      td: string // e.g. "http://demo.pulse.fun/"
    }
  */

  const encodedState = getQueryStringParam('state')
  if (!encodedState) {
    return null
  } else {
    try {
      const state = b64DecodeUnicode(encodedState)
      return JSON.parse(state)
    } catch (err) {
      console.log(err)
      return null
    }
  }
}

function pas() {
  // Get State data from the Auth Service and then set the workspace cookie:
  const state = getStateData()
  const authCode = getQueryStringParam('code')

  if (
    !state ||
    !state.hasOwnProperty('tid') ||
    !state.hasOwnProperty('tn') ||
    !state.hasOwnProperty('td')
  ) {
    console.error('Invalid state param')
    window.location.href = '/login/?pas=isp'
  }

  if (!authCode) {
    console.error('Invalid code param')
    window.location.href = '/login/?pas=icp'
  }

  const cookieName = 'pas_wslist'
  let cookiePayload = []

  const currentCookieValue = Cookies.get(cookieName)
  if (currentCookieValue) {
    try {
      cookiePayload = JSON.parse(currentCookieValue)
      console.log(cookiePayload)
    } catch (err) {
      console.error(err)
      // Pass through w/o loading the current cookie data...
    }
  }

  try {
    if (
      !cookiePayload ||
      (cookiePayload && !cookiePayload.map(t => t.tid).includes(state.tid))
    ) {
      console.log('New workspace!')
    } else {
      console.log('Removing outdated ws data...')
      cookiePayload = cookiePayload.filter(t => t.id !== state.tid)
    }
    cookiePayload.push(state)
  } catch (err) {
    console.error(err)
    cookiePayload.push(state)
  }

  Cookies.set(cookieName, JSON.stringify(cookiePayload), {
    expires: 365,
    secure: window.location.protocol.startsWith('https'),
    sameSite: 'strict',
    domain: window.location.hostname,
  })

  const redirectUrl = new URL(state.td)
  const redirectUrlParams = redirectUrl.searchParams

  redirectUrlParams.set('code', authCode)
  redirectUrl.search = redirectUrlParams.toString()
  console.log(`Done. Redirecting to ${redirectUrl.href}`)
  window.location.href = redirectUrl.href
}

// const testData = {
//   tid: "c4c90371-5708-4d1b-809e-e9c80b88040f",
//   tn: "Sametab Inc",
//   td: "http://localhost:4000/"
// }
// const test = b64EncodeUnicode(JSON.stringify(testData)); // eyJ0aWQiOiJjNGM5MDM3MS01NzA4LTRkMWItODA5ZS1lOWM4MGI4ODA0MGYiLCJ0biI6IlNhbWV0YWIgSW5jIiwidGQiOiJodHRwOi8vbG9jYWxob3N0OjQwMDAvIn0=
// console.log(test);

// Pulse Authentication System - Post Sign in script.
const SignedInPage = () => {
  useEffect(() => {
    pas()
  }, [])

  useHotjarScript()

  return <p>Redirecting to your workspace...</p>
}

export default SignedInPage
